<template>
  <div style="height: 100%">
    <div class="height-100 max_box">
      <div class="card animate__animated animate__fadeIn duration" id="card1">
        <div class="card1_box">
          <p>行情回顾</p>
          <p>{{ extra_param_1[0] }}</p>
          <p>{{ extra_param_1[1] }}</p>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/logotanzhiw.png" alt="" />
        </div>
        <div class="data_source">数据来源：股票灯塔</div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card2"
      >
        <div class="card1_box2">
          <p>摘要</p>
          <p>今日{{ extra_param_2[0] }}报收{{ extra_param_2[1] }}元</p>
          <p>{{ extra_param_2[2] }}：{{ extra_param_2[3] }}%</p>
          <p>成交量：{{ extra_param_2[4] }}万手</p>
          <p>成交额：{{ extra_param_2[5] }}亿</p>
          <p>主力{{ extra_param_2[6] }}：{{ extra_param_2[7] }}万</p>
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card3"
      >
        <div class="card_main">
          <div class="card_title">
            <span>盘面表现</span>
          </div>
          <div class="card_content">
            <p>
              <span>今日报收</span><span>{{ extra_param_3[0] }}</span
              >元,{{ extra_param_3[1]
              }}<span>{{ extra_param_3[2] }}%</span>,上证指数{{
                extra_param_3[3]
              }}<span>{{ extra_param_3[4] }}%</span>,
              {{ extra_param_3[5] }}行业{{ extra_param_3[6]
              }}<span>{{ extra_param_3[7] }}%</span> {{ extra_param_3[8] }}在{{
                extra_param_3[9]
              }}行业中涨幅排名第<span>{{ Number(extra_param_3[10]) }}</span>
            </p>
          </div>
          <div class="chart_content">
            <div id="card2_chart"></div>
          </div>
          <div class="tips">
            风险提示： 本视频相关内容不对各位读者构成任何投资建议，
            据此操作，风险自担
          </div>
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card4"
      >
        <div class="card_main">
          <div class="card_title">
            <span>盘面表现</span>
          </div>
          <div class="card_content card2_k_box">
            <p>
              <span
                class="card2_k_left"
                :class="isRedState ? 'isred' : 'islu'"
                >{{ extra_param_8[0] }}</span
              ><span
                >高<i
                  :class="
                    extra_param_8[3] - extra_param_8[9] > 0 ? 'isred' : 'islu'
                  "
                  >{{ extra_param_8[3] }}</i
                ></span
              ><span
                >开<i
                  :class="
                    extra_param_8[4] - extra_param_8[9] > 0 ? 'isred' : 'islu'
                  "
                  >{{ extra_param_8[4] }}</i
                ></span
              ><span
                >换手<i>{{ extra_param_8[5] }}%</i></span
              >
            </p>
            <p>
              <span class="card2_k_left" :class="isRedState ? 'isred' : 'islu'"
                ><i style="margin-left: 0">{{ extra_param_8[1] }}</i
                ><i>{{ extra_param_8[2] }}%</i></span
              ><span
                >低<i
                  :class="
                    extra_param_8[6] - extra_param_8[9] > 0 ? 'isred' : 'islu'
                  "
                  >{{ extra_param_8[6] }}</i
                ></span
              ><span
                >额<i>{{ extra_param_8[7] }}亿</i></span
              ><span
                >量比<i>{{ extra_param_8[8] }}</i></span
              >
            </p>
          </div>
          <div class="chart_content4 margin-t-18">
            <div class="page_box">
              <div class="chart_holder">
                <div class="macd" id="macd">
                  <span>MA5 {{ ma5 }}</span>
                  <span>MA10 {{ ma10 }}</span>
                  <span>MA20 {{ ma20 }}</span>
                </div>
                <div class="d3_chart d3_k" id="d3K"></div>
                <div class="d3_chart d3_volume" id="d3Volume"></div>
              </div>
            </div>
          </div>
          <div class="tips">
            风险提示： 本视频相关内容不对各位读者构成任何投资建议，
            据此操作，风险自担
          </div>
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card5"
      >
        <div class="card_main">
          <div class="card_title">
            <span>盘面表现</span>
          </div>
          <div class="card_content">
            <p>
              今日报收成交量<span class="num">{{ extra_param_4[0] }}</span
              >万手,五日成交量均值为<span class="num">{{
                extra_param_4[1]
              }}</span
              >万手。
            </p>
          </div>
          <div class="chart_content">
            <div id="card3_chart"></div>
          </div>
          <div class="tips">
            风险提示： 本视频相关内容不对各位读者构成任何投资建议，
            据此操作，风险自担
          </div>
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card6"
      >
        <div class="card_main">
          <div class="card_title">
            <span>主力资金</span>
          </div>
          <div class="card6_box">
            <p>
              <span>主力流入：{{ extra_param_5[0] }}万</span>
              <span>主力流出：{{ extra_param_5[1] }}万</span>
            </p>
            <p>
              {{ extra_param_5[2]
              }}<span class="num">{{ extra_param_5[3] }}</span
              >万，在所属<span>{{ extra_param_5[4] }}</span
              >行业中资金净流入排名第<span class="num">{{
                Number(extra_param_5[5])
              }}</span>
            </p>
          </div>
          <div class="chart_content4">
            <div id="card4_chart1"></div>
            <p>
              主力流入<span>{{ fIn }}</span
              >&nbsp;&nbsp;主力流出<span>{{ fOut }}</span
              >&nbsp;&nbsp;净流入<span>{{ netIn }}</span>
            </p>
            <div id="card4_chart2"></div>
          </div>
          <div class="tips">
            风险提示： 本视频相关内容不对各位读者构成任何投资建议，
            据此操作，风险自担
          </div>
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card7"
      >
        <div class="card_main">
          <div class="card_title">
            <span>盘面表现</span>
          </div>
          <div class="card_content">
            <p>
              <span class="num">{{ extra_param_7.sUpdateDate }}</span
              >日收盘价为<span class="num">{{ extra_param_7.fClosePrice }}</span
              >,压力位<span class="num">{{ extra_param_7.fPressPrice }}</span
              >,支撑位<span class="num">{{ extra_param_7.fSupportPrice }}</span
              >,平均成本<span class="num">{{ extra_param_7.fAvgCost }}</span
              >,目前盈利比例<span class="num">{{
                (extra_param_7.fEarningPer * 100).toFixed(2) + "%"
              }}</span>
            </p>
          </div>
          <div class="chart_content7">
            <p class="progess">
              <span>盈利比例</span><span><i class="progess_i"></i></span
              ><span class="num">{{
                (extra_param_7.fEarningPer * 100).toFixed(2) + "%"
              }}</span>
            </p>
            <div class="line_leged">
              <p class="line_press">
                <span></span>
                <span>压力位</span>
              </p>
              <p class="line_avge">
                <span></span>
                <span>平均成本</span>
              </p>
              <p class="line_support">
                <span></span>
                <span>支撑位</span>
              </p>
            </div>
            <div>
              <div class="d3_chart d3_chip" id="card5_chart"></div>
            </div>
          </div>

          <div class="tips">
            风险提示： 本视频相关内容不对各位读者构成任何投资建议，
            据此操作，风险自担
          </div>
        </div>
      </div>
      <div
        class="card animate__animated animate__fadeIn duration none"
        id="card8"
      >
        <div class="width-100 height-100">
          <video
            class="end_video"
            src="video/end9.mov"
            muted
            width="100%"
            height="100%"
            ref="endVideo"
          ></video>
        </div>
      </div>
    </div>
    <div class="model">
      <Models />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { chipChart } from "@/utils/chipChart";
import { dataProcess } from "@/utils/dataProcess";
import { GetParams2 } from "@/utils/common";
import ApiServe from "@/api/index";
import { drawVolume, drawK } from "@/components/kChart.js";
import Models from "@/components/models/Models.vue";

export default {
  name: "Template9",
  components: { Models },
  data() {
    return {
      audioLengthArr: [],
      recordtimeAll: null,
      extra_param_1: [],
      extra_param_2: [],
      extra_param_3: [],
      extra_param_4: [],
      extra_param_5: [],
      extra_param_6: [],
      extra_param_7: {
        sUpdateDate: "",
        fClosePrice: "",
        fPressPrice: "",
        fSupportPrice: "",
        fAvgCost: "",
        fEarningPer: "",
      },
      extra_param_8: [],
      chart1_x: [],
      chart1_y1: [], //个股
      chart1_y2: [], //大盘
      chart1_y3: [], //行业
      chart1_k: [], //日k图
      chart1_k_x: [], //日k图的x轴
      chart2_x: [], //成交量x轴
      chart2_y: [], //成交量y轴
      chart3_1: [], //资金量的饼状图
      // chart3_x2: [], //资金量的柱状图
      chart3_y2: [], //资金量的柱状图
      chart5_d3: [],
      device_id: null,
      aduioData: [],
      seccode: "", //股票代码
      seccodeShort: "", //股票代码
      secname: "", //股票名
      beginDate: "", //开始日期
      wantNum: "", //需要的天数
      endDate: "", //结束日期
      dataKCard4: [],
      ma5: "",
      ma10: "",
      ma20: "",
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      fIn: "",
      fOut: "",
      netIn: "",
      orderData: [],
      barData: [],
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 9;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { extra_param_1, chart1_x, aduioData } = this;
      return {
        extra_param_1,
        chart1_x,
        aduioData,
      };
    },
    isRedState() {
      if (this.extra_param_8[0] - this.extra_param_8[9] > 0) {
        return true;
      } else {
        return false;
      }
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  watch: {
    allReady(val) {
      if (
        val.extra_param_1.length > 0 &&
        val.chart1_x.length > 0 &&
        val.aduioData.length > 0
      ) {
        if (this.ip && this.port) {
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
          ApiServe.recordstartPc(this.newIpPort, params)
            .then(({ code }) => {
              this.load();
            })
            .catch((err) => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        } else {
          // alert("ip，port没获取到");
          this.load();
        }
      }
    },
  },
  methods: {
    getData() {
      let params = {
        model_id: this.model_id,
      };
      if (this.url_extra_params) {
        params = {
          model_id: this.model_id,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getDataPC(params)
        .then(({ data }) => {
          this.extra_param_1 = data.extra_param_1;
          this.extra_param_2 = data.extra_param_2;
          this.extra_param_3 = data.extra_param_3;
          this.extra_param_4 = data.extra_param_4;
          this.extra_param_5 = data.extra_param_5;
          this.extra_param_6 = data.extra_param_6;
          this.extra_param_8 = data.extra_param_8;
          this.getSecInfo(data.chart_data_3[0].url);
          this.getStock(data.chart_data_1[0].url);
          this.getBig(data.chart_data_1[1].url);
          this.getHangye(data.chart_data_1[2].url);
          this.getRiK(data.chart_data_1[3].url);
          this.getCmfbwz(data.extra_param_7.url, data.extra_param_7.payload);
          this.chart2_x = data.chart_data_2[0].data.x || [];
          this.chart2_y = data.chart_data_2[0].data.y || [];
          this.chart3_1 = (data.chart_data_3[0].data["类型"] || []).map(
            (item, index) => {
              return {
                value: data.chart_data_3[0].data["比例"][index],
                name: item,
              };
            }
          );
          // this.chart3_x2 = ["超大单", "大单", "中单", "小单"] || [];

          this.chart3_y2 = data.chart_data_4[0].data.y || [];
          if (this.ip && this.extra_param_1.length == 0) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
    // 获取旁白
    getAudio() {
      let params = {
        model_id: this.model_id,
      };
      if (this.url_extra_params) {
        params = {
          model_id: this.model_id,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getVideoDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.aduioData = data || [];
            this.audioLengthArr = data.map((item) => {
              return item.scene_duration;
            });
            this.recordtimeAll =
              this.audioLengthArr.reduce(function (prev, curr, idx, arr) {
                return prev + curr;
              }) +
              15 +
              3;
            // 结尾视频3秒
            if (this.ip && this.aduioData.length == 0) {
              ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
    //获取主力资金
    async getSecInfo(url) {
      const urlParams1 = encodeURIComponent(url);
      const newUrl = this.newIpPort + "/dtpostapiproxy/?api=" + urlParams1;
      let { content, ret } = await this.$axios.get(newUrl);

      console.log(content);
      if (ret == 0) {
        const vCapitalFlow = JSON.parse(content).vCapitalFlow[0];
        console.log(vCapitalFlow);
        this.fIn = (
          (vCapitalFlow.fMidin + vCapitalFlow.fSmallin) /
          10000
        ).toFixed(2);
        this.fOut = (
          (vCapitalFlow.fMidout + vCapitalFlow.fSmallout) /
          10000
        ).toFixed(2);
        this.netIn = (
          (vCapitalFlow.fMidin +
            vCapitalFlow.fSmallin -
            vCapitalFlow.fMidout -
            vCapitalFlow.fSmallout) /
          10000
        ).toFixed(2);
        console.log(this.fIn);
        //超大单
        const fSuper = (
          (vCapitalFlow.fSuperin - vCapitalFlow.fSuperout) /
          10000
        ).toFixed(2);
        //大单
        const fBig = (
          (vCapitalFlow.fBigin - vCapitalFlow.fBigout) /
          10000
        ).toFixed(2);
        //中单
        const fMid = (
          (vCapitalFlow.fBigin - vCapitalFlow.fBigout) /
          10000
        ).toFixed(2);

        //小单
        const fSmall = (
          (vCapitalFlow.fSmallin - vCapitalFlow.fSmallout) /
          10000
        ).toFixed(2);
        this.orderData.push(fSuper, fBig, fMid, fSmall);
        console.log(this.orderData);

        //主力流入%
        const mIn = (
          ((vCapitalFlow.fSuperin + vCapitalFlow.fBigin) /
            (vCapitalFlow.fSuperin +
              vCapitalFlow.fBigin +
              vCapitalFlow.fMidin +
              vCapitalFlow.fSmallin)) *
          0.5 *
          100
        ).toFixed(0);

        //散户流入%
        const sIn = (
          ((vCapitalFlow.fMidin + vCapitalFlow.fSmallin) /
            (vCapitalFlow.fSuperin +
              vCapitalFlow.fBigin +
              vCapitalFlow.fMidin +
              vCapitalFlow.fSmallin)) *
          0.5 *
          100
        ).toFixed(0);
        //主力流出%
        const mOut = (
          ((vCapitalFlow.fSuperout + vCapitalFlow.fBigout) /
            (vCapitalFlow.fSuperout +
              vCapitalFlow.fBigout +
              vCapitalFlow.fMidout +
              vCapitalFlow.fSmallout)) *
          0.5 *
          100
        ).toFixed(0);
        //散户流出流入%
        const sOut = (
          ((vCapitalFlow.fMidout + vCapitalFlow.fSmallout) /
            (vCapitalFlow.fSuperout +
              vCapitalFlow.fBigout +
              vCapitalFlow.fMidout +
              vCapitalFlow.fSmallout)) *
          0.5 *
          100
        ).toFixed(0);
        this.barData.push(
          { value: mOut, name: "主力流出" },
          { value: sOut, name: "散户流出" },
          { value: sIn, name: "散户流入" },
          { value: mIn, name: "主力流入" }
        );
        console.log(this.barData);
      }
    },

    // 获取个股数据接口
    async getStock(url) {
      let { content, ret } = await this.$axios.get(url);
      if (ret == 0) {
        const fPreClosePrice = JSON.parse(content).fPreClosePrice;
        JSON.parse(content).vTrendDesc.forEach((item, index) => {
          this.chart1_x.push(
            Math.floor(item.iMinute / 60) +
              ":" +
              (item.iMinute % 60 > 9
                ? item.iMinute % 60
                : "0" + (item.iMinute % 60))
          );
          this.chart1_y1.push(
            ((item.fNow - fPreClosePrice) / fPreClosePrice) * 100
          );
          if (this.ip && this.chart1_x.length == 0) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
      }
    },
    // 获取大盘数据接口
    async getBig(url) {
      let { content, ret } = await this.$axios.get(url);
      if (ret == 0) {
        const fPreClosePrice = JSON.parse(content).fPreClosePrice;
        JSON.parse(content).vTrendDesc.forEach((item, index) => {
          this.chart1_y2.push(
            ((item.fNow - fPreClosePrice) / fPreClosePrice) * 100
          );
        });
      }
    },
    // 获取行业数据接口
    async getHangye(url) {
      let { content, ret } = await this.$axios.get(url);
      if (ret == 0) {
        const fPreClosePrice = JSON.parse(content).fPreClosePrice;
        JSON.parse(content).vTrendDesc.forEach((item, index) => {
          this.chart1_y3.push(
            ((item.fNow - fPreClosePrice) / fPreClosePrice) * 100
          );
        });
      }
    },
    // 获取日K数据接口
    async getRiK(url) {
      this.seccode = "0001000783";
      this.endDate = this.$moment().format("YYYYMMDD");
      this.seccodeShort = this.seccode.substr(4);
      this.ajaxKByDate();
      this.ajaxSecname();
    },
    // 获取筹码分布数据
    async getCmfbwz(url, params1) {
      const urlParams1 = encodeURIComponent(url);
      const urlParams2 = encodeURIComponent(JSON.stringify(params1));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      // console.log(newUrl)
      let { content, ret } = await this.$axios.get(newUrl);
      if (ret == 0) {
        let dataObj = JSON.parse(content).vtChipDistRsp;
        this.dataList = dataObj;
        this.extra_param_7 = dataObj[0]; //筹码分布文字
        //获得所有帧里的最大值和最小值
        var minMax = dataProcess.getMinMax(dataObj, "vStockChipDist");
        //数据格式转换供图表用
        for (var i = 0; i < dataObj.length; i++) {
          dataObj[i].dataConverted = dataProcess.convertData({
            data: dataObj[i].vStockChipDist,
            name: "fPrice",
            value: "fPer",
            avePrice: dataObj[i].fAvgCost,
            closePrice: dataObj[i].fClosePrice,
            pressPrice: dataObj[i].fPressPrice,
            supportPrice: dataObj[i].fSupportPrice,
            xMax: minMax.xMax,
            yMax: minMax.yMax,
            yMin: minMax.yMin,
          });
        }
        this.chart5_d3 = JSON.parse(JSON.stringify(dataObj)).reverse();
      }
    },
    //根据begin和end date获取日k
    async ajaxKByDate() {
      const params = {
        sDtSecCode: this.seccode,
        eKLineType: 4,
        sEndDate: this.endDate,
        iWantnum: 50,
      };
      const urlParams1 = encodeURIComponent(
        "https://comm.wedengta.com/?s=kline&f=getKLineByDate&req=KLineDateReq&rsp=KLineDateRsp"
      );
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      const data = await this.$axios.get(newUrl);
      const _data = JSON.parse(data.content).vKLineDesc.reverse();
      this.dataKCard4 = _data;
      console.log("_data", _data);
    },
    async ajaxSecname() {
      const params = {
        vDtSecCode: [this.seccode],
      };
      const urlParams1 = encodeURIComponent(
        "https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp"
      );
      const urlParams2 = encodeURIComponent(JSON.stringify(params));
      const newUrl =
        this.newIpPort +
        "/dtpostapiproxy/?api=" +
        urlParams1 +
        "&payloads=" +
        urlParams2;
      const data = await this.$axios.get(newUrl);
      const _data = JSON.parse(data.content).vSecSimpleQuote;
      this.secname = _data[0].sSecName;
    },
    renderKLine(fullKData) {
      // const data = fullKData.slice(frameIndex, frameIndex + 50);
      //用50条数据，算出30条准确的均线数值
      const ave5 = this.getAverageValue(5, fullKData).slice(20, 50);
      const ave10 = this.getAverageValue(10, fullKData).slice(20, 50);
      const ave20 = this.getAverageValue(20, fullKData).slice(20, 50);
      // console.log("ave5", ave5);

      this.ma5 = ave5[ave5.length - 1].toFixed(2);
      this.ma10 = ave10[ave10.length - 1].toFixed(2);
      this.ma20 = ave20[ave20.length - 1].toFixed(2);

      //30条里加入均线数据
      const dataCombined = this.combineKAve(
        fullKData.slice(20, 50),
        ave5,
        ave10,
        ave20
      );
      console.log("dataCombined", dataCombined);

      const chartWidth = document.documentElement.clientWidth - 30;
      const totalChartHeight = document.documentElement.clientHeight - 552;
      const kRatio = (totalChartHeight * (3 / 4)) / chartWidth;
      const volumeRatio = (totalChartHeight * (1 / 4)) / chartWidth;

      //画k线和量
      drawK({
        id: "d3K",
        data: dataCombined,
        width: chartWidth,
        ratio: kRatio,
      });
      drawVolume({
        id: "d3Volume",
        data: dataCombined,
        width: chartWidth,
        ratio: volumeRatio,
      });
    },
    //获取均线
    getAverageValue: function (num, data) {
      let averageValue = [];
      let totalSum = 0;
      if (data.length <= num) {
        for (let i = 0; i < data.length; i++) {
          totalSum += data[i].fClose;
          averageValue[i] = totalSum / (i + 1);
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          if (i < num) {
            totalSum += data[i].fClose;
            averageValue[i] = totalSum / (i + 1);
          } else {
            totalSum = 0;
            for (let j = i; j > i - num && j > 0; j--) {
              if (i - j < num) {
                totalSum += data[j].fClose;
              }
            }
            averageValue[i] = totalSum / num;
          }
        }
      }
      return averageValue;
    },
    //合并均线数据到k线数据
    combineKAve: function (data, ave5, ave10, ave20) {
      var _data = data;
      for (var i = 0; i < _data.length; i++) {
        _data[i].ave5 = ave5[i];
        _data[i].ave10 = ave10[i];
        _data[i].ave20 = ave20[i];
      }
      return _data;
    },

    // 盘面表现图表
    loadChart1() {
      var myChart = this.$echarts.init(
        document.getElementById("card2_chart"),
        null,
        { renderer: "svg" }
      );
      var option = {
        textStyle: {
          fontSize: 24,
        },
        color: ["#36FDFF", "#EF622E", "#F4D73D"],
        legend: {
          data: ["个股", "大盘", "行业"],
          top: 10,
          textStyle: {
            color: (params) => params,
          },
        },
        grid: {
          left: "4%",
          right: "20px",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chart1_x,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 24,
          },
          axisLine: {
            lineStyle: {
              color: "#414469",
            },
          },
        },
        animationDuration: 4000,
        yAxis: {
          position: "right",
          type: "value",
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#414469",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisLabel: {
            margin: 16,
            fontSize: 24,
            formatter: function (value) {
              return value.toFixed(2) + "%";
            },
          },
        },
        series: [
          {
            name: "个股",
            type: "line",
            color: "#36FDFF",
            data: this.chart1_y1,
            animationDelay: function (idx) {
              return idx * 100;
            },
          },
          {
            name: "大盘",
            type: "line",
            color: "#EF622E",
            data: this.chart1_y2,
            animationDelay: function (idx) {
              return idx * 100 + 100;
            },
          },
          {
            name: "行业",
            type: "line",
            color: "#F4D73D",
            data: this.chart1_y3,
            animationDelay: function (idx) {
              return idx * 100 + 200;
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 盘面表现-日K图
    loadChart1_k() {
      var myChart = this.$echarts.init(
        document.getElementById("card2_chart_k"),
        null,
        { renderer: "svg" }
      );
      var option = (option = {
        backgroundColor: "#fff", // 背景色
        xAxis: {
          //x轴设置
          type: "category", // 坐标轴类型。'value' 数值轴，适用于连续数据。'category' 类目轴，适用于离散的类目数据.'time' 时间轴，适用于连续的时序数据.'log' 对数轴。适用于对数数据。
          data: this.chart1_k_x, // x轴数据循环方法返回结果
          axisLine: { lineStyle: { color: "#8392A5" } }, // 底部x轴颜色
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          // y轴设置
          scale: true, //是否是脱离 0 值比例。设置成 true 后坐标刻度不会强制包含零刻度。在双数值轴的散点图中比较有用。
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          }, //是否显示分隔线。默认数值轴显示，类目轴不显示。
          axisTick: {
            show: false,
          },
          axisLine: {
            //y轴
            show: false,
          },
        },
        grid: {
          //直角坐标系内绘图网格，单个 grid 内最多可以放置上下两个 X 轴，左右两个 Y 轴。可以在网格上绘制折线图，柱状图，散点图（气泡图）。
          bottom: 20, //grid 组件离容器下侧的距离。单位像素
          right: 10,
          top: 20,
        },
        animationDuration: 4000,
        animation: false, // 翻页动画
        // 这里开始设置日k线,如单日,5日,10日,20日,30日
        series: [
          //系列列表。每个系列通过 type 决定自己的图表类型
          {
            type: "candlestick", // 显示种类,如线性等
            name: "日K",
            data: this.chart1_k, // 日k线每日的数据
            itemStyle: {
              // 对象样式
              normal: {
                color: "#FD1050",
                color0: "#0CF49B",
                borderColor: "#FD1050",
                borderColor0: "#0CF49B",
              },
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    // 成交量
    loadChart2() {
      var myChart = this.$echarts.init(
        document.getElementById("card3_chart"),
        null,
        { renderer: "svg" }
      );
      var option = {
        textStyle: {
          fontSize: 24,
        },
        legend: {
          data: ["成交量(万手)"],
          left: 10,
          top: 10,
          textStyle: {
            fontSize: 24,
            color: "#5563D9",
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "40px",
          top: "40px",
          // containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.chart2_x,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 24,
          },
        },
        animationDuration: 4000,
        yAxis: {
          show: false,
        },
        series: [
          {
            data: this.chart2_y,
            z: -1,
            name: "成交量(万手)",
            type: "bar",
            barWidth: 48,
            label: {
              show: true,
              position: "top",
              fontSize: 24,
              color: "#5867DB",
            },
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#7D9CF3" }, //柱图渐变色
                  { offset: 0.5, color: "#7D9CF3" }, //柱图渐变色
                  { offset: 1, color: "#5563D9" }, //柱图渐变色
                ]),
              },
            },
            markLine: {
              symbol: "none",
              label: {
                distance: [-180, 0],
                position: "end",
                color: "#36FDFF",
                formatter: function (val) {
                  return "成交均值" + val.value;
                },
              },
              lineStyle: {
                color: "#36FDFF",
              },
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 资金量--饼图
    loadChart3() {
      var myChart = this.$echarts.init(
        document.getElementById("card4_chart1"),
        null,
        { renderer: "svg" }
      );
      var option = {
        animationDuration: 4000,
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            label: {
              color: "inherit",
              formatter: function ({ value, name }) {
                return name + value + "%";
              },
              fontSize: 24,
            },
            data: this.barData,
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = ["#E16053", "#FD834E", "#21872F", "#3FC03D"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 资金量--柱状图
    loadChart4() {
      var myChart = this.$echarts.init(
        document.getElementById("card4_chart2"),
        null,
        { renderer: "svg" }
      );
      var option = {
        grid: {
          left: "0",
          right: "0",
          bottom: "40px",
          top: "40px",
        },
        xAxis: {
          type: "category",
          data: ["超大单", "大单", "中单", "小单"],
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 24,
          },
        },
        animationDuration: 4000,
        yAxis: {
          show: false,
        },
        series: [
          {
            type: "bar",
            barWidth: 30,
            label: {
              show: true,
              position: "top",
            },
            data: this.orderData.map((item) => {
              return {
                value: Math.abs(item),
                label: {
                  // 设置显示label
                  show: true,
                  // 设置label的位置
                  position: "top",
                  // 设置label的文字颜色
                  color: item > 0 ? "#FF854E" : "#21872F",
                  formatter: function (item) {
                    // console.log(item);
                    if (item.color == "#21872F") {
                      return "-" + item.value;
                    }
                  },
                  fontSize: 24,
                },
                itemStyle: {
                  color: item > 0 ? "#FF854E" : "#21872F",
                },
              };
            }),
          },
        ],
      };
      myChart.setOption(option);
    },
    // 筹码分布
    loadChart5D3(currentPlayingFrame = 0) {
      const totalPlayingFrame = this.chart5_d3.length;
      setTimeout(() => {
        if (currentPlayingFrame < totalPlayingFrame) {
          currentPlayingFrame++;
          chipChart.drawChip({
            id: "card5_chart",
            data: this.chart5_d3[currentPlayingFrame - 1].dataConverted,
            width: document.documentElement.clientWidth - 30,
            ratio: 1,
          });
          this.extra_param_7 = this.chart5_d3[currentPlayingFrame - 1]; //渲染图上面的那段话的数字
          let progessNum =
            (this.extra_param_7.fEarningPer * 100).toFixed(2) + "%";
          $(".progess_i").css("width", progessNum);
          this.loadChart5D3(currentPlayingFrame); //渲染图
        }
      }, 300);
    },
    endRecord() {
      let arr = [
        2,
        3 + this.audioLengthArr[0],
        11 + this.audioLengthArr[0] + this.audioLengthArr[1],
        13 +
          this.audioLengthArr[0] +
          this.audioLengthArr[1] +
          this.audioLengthArr[2],
        14 +
          this.audioLengthArr[0] +
          this.audioLengthArr[1] +
          this.audioLengthArr[2] +
          this.audioLengthArr[3],
      ];
      let audiosOrderList = this.aduioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: arr[index],
          audioLen: item.audio_length,
        };
      });
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: this.recordtimeAll,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    load() {
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 1000); //间隔
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
      }, 2000); //间隔
      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, (2 + this.audioLengthArr[0]) * 1000);
      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.loadChart1();
      }, (3 + this.audioLengthArr[0]) * 1000); //间隔
      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, (3 + this.audioLengthArr[0] + this.audioLengthArr[1]) * 1000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.renderKLine(this.dataKCard4);
      }, (4 + this.audioLengthArr[0] + this.audioLengthArr[1]) * 1000); //间隔
      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, (10 + this.audioLengthArr[0] + this.audioLengthArr[1]) * 1000);
      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.loadChart2();
      }, (11 + this.audioLengthArr[0] + this.audioLengthArr[1]) * 1000); //间隔
      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, (12 + this.audioLengthArr[0] + this.audioLengthArr[1] + this.audioLengthArr[2]) * 1000);
      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.loadChart3();
        this.loadChart4();
      }, (13 + this.audioLengthArr[0] + this.audioLengthArr[1] + this.audioLengthArr[2]) * 1000); //间隔
      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, (13 + this.audioLengthArr[0] + this.audioLengthArr[1] + this.audioLengthArr[2] + this.audioLengthArr[3]) * 1000);
      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        this.loadChart5D3();
      }, (14 + this.audioLengthArr[0] + this.audioLengthArr[1] + this.audioLengthArr[2] + this.audioLengthArr[3]) * 1000);
      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, (14 + this.audioLengthArr[0] + this.audioLengthArr[1] + this.audioLengthArr[2] + this.audioLengthArr[3] + this.audioLengthArr[4]) * 1000);
      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        this.$refs.endVideo.play();
      }, (15 + this.audioLengthArr[0] + this.audioLengthArr[1] + this.audioLengthArr[2] + this.audioLengthArr[3] + this.audioLengthArr[4]) * 1000); //间隔
      // 总时间 67秒
      if (this.ip && this.port) {
        setTimeout(() => {
          this.endRecord();
        }, this.recordtimeAll * 1000);
      }
    },
  },
};
</script>
<style scoped lang="less">
@import "@/assets/css/common.less";
.max_box {
  background: url("../../assets/img/bg9.png") no-repeat center;
  background-size: cover;
}
.card {
  background: transparent;
  color: #36fdff;
  font-size: 24px;
}
#card1 {
  font-size: 14px * @beishu;
}
.logo-box {
  width: 166px;
  bottom: 160px;
}
.data_source {
  position: absolute;
  bottom: 100px;
  text-align: center;
  width: 100%;
  font-size: 24px;
  color: #fff;
}
.card1_box {
  position: absolute;
  top: 243px;
  left: 92px;
  right: 92px;
  width: 536px;
  height: 536px;
  text-align: center;
  color: #36fdff;
  background: url("../../assets/img/bg9-1.png") no-repeat center;
  background-size: cover;
}
.card1_box p:first-of-type {
  font-size: 32px;
  margin-top: 148px;
}
.card1_box p:nth-of-type(2) {
  font-size: 64px;
  margin-top: 48px;
  font-weight: 700;
}
.card1_box p:nth-of-type(3) {
  font-size: 32px;
  margin-top: 24px;
}
.card1_box2 {
  position: absolute;
  top: 300px;
  left: 173px;
  right: 173px;
  text-align: center;
  color: #36fdff;
  font-size: 32px;
  p:first-of-type {
    font-size: 64px;
  }
  P:nth-of-type(2) {
    margin-top: 80px;
  }
  p {
    margin-top: 10px;
  }
}
.card_title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.card_title span:first-of-type {
  font-size: 36px;
  font-weight: 700;
}
.card_main {
  position: absolute;
  top: 5%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 24px;
}
.card_content {
  margin: 24px 0 16px 0;
  padding: 30px 20px;
  background: #161724;
  line-height: 48px;
  font-size: 28px;
}
.chart_content {
  height: 720px;
  background: #161724;
}
/* .chart_content2 {
  height: 400px;
} */
.chart_content > div {
  height: 100%;
  width: 100%;
}
.chart_content4 {
  height: 900px;
}
.chart_content4 > p {
  font-size: 12px * @beishu;
  text-align: center;
}
.chart_content4 > p span:first-of-type {
  color: #df813c;
  margin-right: 8px;
}
.chart_content4 > p span:nth-of-type(2) {
  color: #3a49dc;
  margin-right: 8px;
}
.chart_content4 > p span:nth-of-type(3) {
  color: #df813c;
}
.chart_content4 .card4_chart2_des {
  display: flex;
}
.chart_content4 .card4_chart2_des span {
  width: 25%;
  text-align: center;
}
#card4_chart1 {
  height: 440px;
  width: 100%;
}
#card4_chart2 {
  height: 400px;
  width: 100%;
}
#card5_chart {
  width: 100%;
  height: 700px;
  font-size: 20px;
}
.progess,
.line_leged {
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin: 8px 16px;
}
.line_leged .line_press {
  color: #be10e3;
}
.line_leged .line_press span:first-of-type {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #be10e3;
}
.line_leged .line_avge {
  color: #ffa942;
  margin: 0 32px;
}
.line_leged .line_avge span:first-of-type {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ffa942;
}
.line_leged .line_support {
  color: #2b7dc7;
}
.line_leged .line_support span:first-of-type {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2b7dc7;
}
.progess span:nth-of-type(2) {
  width: 100px;
  margin: 0 16px;
  height: 30px;
}
.progess .progess_i {
  display: inline-block;
  height: 10px;
  width: 100%;
  border-radius: 20px;
  background: #df813c;
}
.card2_k_box {
  color: #fff;
  padding: 30px 0;
}
.card2_k_box p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.card2_k_box p span:nth-of-type(2),
.card2_k_box p span:nth-of-type(3) {
  flex: 1;
}
.card2_k_box p .card2_k_left {
  width: 210px;
  text-align: center;
}
.card2_k_box p span:nth-of-type(4) {
  width: 160px;
  text-align: left;
}
.card2_k_box p span i {
  margin-left: 4px;
  font-size: 14px * @beishu;
}
.card2_k_box > p:first-of-type .card2_k_left {
  font-size: 22px * @beishu;
  text-align: center;
}
.islu {
  color: #00b466;
}
.isred {
  color: #e54444;
}
.tips {
  position: absolute;
  left: 96px;
  right: 96px;
  bottom: 48px;
  text-align: center;
  color: #7e84af;
}
.card6_box {
  margin-top: 32px;
  > p:first-of-type {
    display: flex;
    justify-content: space-between;
    > span:first-of-type {
      color: #ff5a1d;
    }
    > span:nth-of-type(2) {
      color: #10ff1a;
    }
  }
  > p:nth-of-type(2) {
    margin-top: 10px;
  }
}
.chart_content7 {
  background: #161724;
  padding-top: 10px;
}
.model {
  position: absolute;
  top: 600px;
  right: 0px;
  width: 450px;
  height: 800px;
}
</style>
