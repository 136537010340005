<template>
  <div class="container" ref="container"></div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

export default {
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      clock: null,
      height: 800,
      width: 450,

      model: null, // 模型
      mixer: null, // 加载动画
      actions: {}, // 模型所有动画
      activeAction: null,
      previousAction: null,
      states: [
        "Idle",
        "Walking",
        "Running",
        "Dance",
        "Death",
        "Sitting",
        "Standing",
      ],
      activeStates: "Idle",
      emotes: ["Jump", "Yes", "No", "Wave", "Punch", "ThumbsUp"],

      randomStates: [
        "Walking",
        "Running",
        "Dance",
        "Sitting",
        "Standing",
        "Jump",
        "Yes",
        "No",
        "Wave",
        "Punch",
        "ThumbsUp",
      ],
    };
  },
  mounted() {
    this.init();
    this.animate();
    this.run();
  },
  methods: {
    //初始化
    init() {
      //  创建场景对象Scene
      this.scene = new THREE.Scene();
      this.clock = new THREE.Clock();

      const that = this;

      // lights
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemiLight.position.set(0, 20, 0);
      this.scene.add(hemiLight);

      const dirLight = new THREE.DirectionalLight(0xffffff);
      dirLight.position.set(-30, 20, 10);
      this.scene.add(dirLight);

      //网格模型添加到场景中
      const loader = new GLTFLoader();
      loader.load(
        "models/gltf/RobotExpressive/RobotExpressive.glb",
        function (gltf) {
          console.log('gltf',gltf);
          that.model = gltf.scene;
          that.model.visible = false;
          that.scene.add(that.model);

          that.getAnimation(that.model, gltf.animations);
        },
        undefined,
        function (e) {
          console.error(e);
        }
      );

      /**
       * 相机设置
       */
      let container = this.$refs["container"];
      this.camera = new THREE.PerspectiveCamera(
        45,
        this.width / this.height,
        0.1,
        100
      );
      this.camera.position.set(-30, 0, 0);
      this.camera.lookAt(new THREE.Vector3(0, 2, 0));

      /**
       * 创建渲染器对象
       */
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(this.renderer.domElement);

      //创建控件对象
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    },

    // 动画
    animate() {
      const dt = this.clock.getDelta();
      if (this.mixer) this.mixer.update(dt);

      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    // 获取动画
    getAnimation(model, animations) {
      // this.model.rotation.y = -Math.PI / 4;

      this.mixer = new THREE.AnimationMixer(model);

      for (let i = 0; i < animations.length; i++) {
        const clip = animations[i];
        const action = this.mixer.clipAction(clip);
        // console.log('action',action);
        this.actions[clip.name] = action;

        // if (
        //   this.emotes.indexOf(clip.name) >= 0 ||
        //   this.states.indexOf(clip.name) >= 4
        // ) {
        //   action.clampWhenFinished = true;
        //   action.loop = THREE.LoopOnce;
        // }
      }

      // setTimeout(() => {
      //   this.model.rotation.y = (-3 * Math.PI) / 4;
      //   this.model.position.z = 2.5;

      //   this.activeAction = this.actions["Jump"];
      //   this.activeAction.play();
      // }, 1000);
    },
    // 设置动画
    setAnimation(name) {
      this.fadeToAction(name, 0.2);
      this.mixer.addEventListener("finished", this.restoreState);
    },
    // 执行动画
    fadeToAction(name, duration) {
      this.previousAction = this.activeAction;
      this.activeAction = this.actions[name];

      if (this.previousAction !== this.activeAction) {
        this.previousAction.fadeOut(duration);
      }

      // console.log('this.activeAction',this.activeAction);
      this.activeAction
        .reset()
        .setEffectiveTimeScale(1)
        .setEffectiveWeight(1)
        .fadeIn(duration)
        .play();
    },
    restoreState() {
      this.mixer.removeEventListener("finished", this.restoreState);
      this.fadeToAction(this.activeStates, 0.2);
    },
    run() {
      setTimeout(() => {
        this.model.visible = true;
        this.setAnimation("Jump");
      }, 5000);

      // setTimeout(() => {
      //   this.setAnimation(this.states[2]);
      // }, 2000);
      // this.redict = 1;

      // setTimeout(() => {
      //   this.setAnimation(this.emotes[2]);
      // }, 3000);
      // setTimeout(() => {
      //   this.setAnimation(this.emotes[3]);
      // }, 4000);

      setInterval(() => {
        // if (this.activeAction !== this.actions["Running"]) return;

        let index = Math.ceil(Math.random() * 10);
        this.setAnimation(this.randomStates[index]);
      }, 3000);

      // setInterval(() => {
      //   if (this.activeAction !== this.actions["Running"]) return;
      //   if (this.model.position.z > 8) {
      //     this.model.rotation.y = (-3 * Math.PI) / 4;
      //     this.redict = -1;
      //   }
      //   if (this.model.position.z < -8) {
      //     this.model.rotation.y = -Math.PI / 4;
      //     this.redict = 1;
      //   }
      //   this.model.position.z += this.redict * 0.2;
      // }, 200);
    },
  },
};
</script>

<style>
.container {
  width: 450px;
  height: 800px;
}
</style>
